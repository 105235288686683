import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Toast } from 'ngx-toastr';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent extends Toast {}
