import { inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthUserService } from '../@auth/services/auth-user.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private toastrService = inject(ToastrService);
  private authUserService = inject(AuthUserService);

  handleUnauthorizedError(): void {
    if (!this.authUserService.isAuthenticated()) return;
    this.toastrService.error(
      'Your session has expired. You will be redirected to the login page in 3 second.',
      'Session Expired',
    );
    setTimeout(() => {
      this.authUserService.logout();
    }, 3000);
  }

  handleForbiddenError() {
    this.toastrService.error(
      'You are not authorized to access this resource. Please contact your administrator.',
      'Forbidden',
    );
  }

  handleNotFoundError() {
    this.toastrService.error(
      'The requested resource was not found.',
      'Not Found',
    );
  }

  handleInternalServerError(error: HttpErrorResponse) {
    this.toastrService.error(
      'An unexpected error occurred. Please try again later.',
      'Internal Server Error',
    );
  }

  handleBadRequestError(error: HttpErrorResponse) {
    this.toastrService.error(
      this.getErrorMessage(error),
      this.getErrorTitle(error),
    );
  }

  getErrorTitle(error: HttpErrorResponse) {
    if (error.error.title) {
      return this.getError1Title(error.error as IError1);
    } else {
      return 'Bad Request';
    }
  }

  getErrorMessage(error: HttpErrorResponse) {
    if (error.error.title) {
      return this.getError1Message(error.error as IError1);
    } else {
      return this.getError2Message(error.error as IError2);
    }
  }

  getError1Title(error: IError1) {
    return error.title;
  }

  getError1Message(error: IError1) {
    return (
      error.errors[Object.keys(error.errors)[0]][0] ||
      'The request was invalid. Please try again.'
    );
  }

  getError2Message(error: IError2) {
    return (
      error.errors[0]?.message || 'The request was invalid. Please try again.'
    );
  }
}

export interface IError1 {
  type: string;
  title: string;
  status: number;
  errors: {
    [key: string]: string[];
  };
  traceId: string;
}

export interface IError2 {
  isSingleError: boolean;
  errors: {
    message: string;
    field: string;
    metadata: any;
  }[];
  detailedErrors: null;
}
