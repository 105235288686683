import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CodeEditorModule } from '@ngstack/code-editor';
import { EaiChatModule } from '@corvus-it-poland/eai-chat';
import { AuthInterceptorInterceptor } from './@auth/interceptors/auth-interceptor.interceptor';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MarkdownModule } from 'ngx-markdown';
import { ToastrModule } from 'ngx-toastr';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { environment } from '../environments/environment';
import { ErrorsInterceptor } from './@errors/errors.interceptor';
import { ToastComponent } from './cms/shared/components/toast/toast.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    NgJsonEditorModule,
    CodeEditorModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    MarkdownModule.forRoot(),
    EaiChatModule.forRoot({
      enabled: true,
      apiUrl: 'https://api.quick-ai.corvus-it.com',
      socketUrl: 'wss://api.quick-ai.corvus-it.com',
      localStoragePrefix: 'eai-chat',
      env: environment.envName,
      iFrameMode: false,
    }),
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
    }),
  ],
  providers: [
    {
      provide: MatDialogRef,

      useValue: {},
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
