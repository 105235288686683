import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';

export class ErrorsInterceptor implements HttpInterceptor {
  private errorHandlerService = inject(ErrorHandlerService);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 400:
            this.errorHandlerService.handleBadRequestError(error);
            break;
          case 401:
            this.errorHandlerService.handleUnauthorizedError();
            break;
          case 403:
            this.errorHandlerService.handleForbiddenError();
            break;
          case 404:
            this.errorHandlerService.handleNotFoundError();
            break;
          case 500:
            this.errorHandlerService.handleInternalServerError(error);
            break;

          default:
            this.errorHandlerService.handleBadRequestError(error);
        }
        return throwError(() => error);
      }),
    );
  }
}
